<template>
  <div>
    <!-- pic3A68C9858E5A630CC45FEE19639BEF54.jpg -->
    <img class="leftimage rounded" style="width: 240px; height: 180px;" src="@/assets/news-network/lu-newsletter-1.jpg">

    <p>
      Subscribers know that the LEGO Universe email newsletter is <b>free</b>, fun, and packed with inside scoops on the game!
    </p>
    <p>
      Past newsletters have wowed readers with first looks at articles and other sneak peeks! And every edition comes with a new VIP code! The codes will get you first access to special gifts like helpful kits for use in the Creation Lab and collectible LEGO Universe animations!
    </p>
    <p>
      What's more, LEGO Universe newsletter subscribers will be first to know when beta testing begins! It's the spot to get insider information about the game as we draw closer to the big LEGO Universe launch!!
    </p>
    <p>
      <router-link :to="{name: 'newsletter'}">Sign up</router-link> if you haven't already!
    </p>

    <!-- pic52FD23492951567BA6C14FBF18EC1709.jpg -->
    <img class="rounded" style="width: 440px; height: 330px;" src="@/assets/news-network/lu-newsletter-2.jpg">

    <div class="clear"></div>
  </div>
</template>
